<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 appointment_history allergies">
            <div class="a_header">
                <div class="cmpltd-dltd-felter-sect">
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" @keyup="taskSearch" class="form-control" placeholder="Search Task" v-model="form.keyword" @blur="taskSearch" ref="globalSearch">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length}}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="getCompDelTaskList"></CustomFilter>
                    </span>
                    <button :class="['comman_brdr_btn', 'taskActive-brdr', { disabled: selectedItems.length === 0 }]" :disabled="selectedItems.length === 0" @click="selectedItems.length !== 0 && activeTask('Active')">Activate</button>
                </div>
            </div>
            <div class="table-responsive consult-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset w-50px"><input class="form-check-input" type="checkbox" v-model="selectAllChk" @change="checkSelectAll"></th>
                            <th scope="col" class="active" @click="changeSort('created_datetime', $event)" style="width: calc(var(--scale-ratio) * 182px);"> Create Date <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="changeSort('reminder_message', $event)" style="width: calc(var(--scale-ratio) * 380px);"> Message <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="changeSort('patient_name', $event)"> Demographic <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="changeSort('provider_ids', $event)" style="width: calc(var(--scale-ratio) * 480px);"> Assigned Providers <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="changeSort('priority', $event)"> Status <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.allCompDelTasksList" :key="index" :class="{ 'active': (selectedItems.includes(item.id) ) }">
                            <td><input class="form-check-input" type="checkbox" :checked="isChecked(item.id)" @change="checkSelect(item.id)"></td>
                            <td class="text-wrap-nowrap">{{ $filters.inputDateTime(item.created_datetime, 'YYYY-MM-DD') }}</td>
                            <td style="text-wrap: unset;">{{ $filters.capitalizeFirstWordFirstLetter(item.reminder_message) }}</td>
                            <td>{{ item.patient_name }}</td>
                            <td style="text-wrap: unset;">{{ item.provider_ids }}</td>
                            <td>{{ item.status }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>
<script>
import sidebar from './sidebar.vue';
import CustomFilter from '../../base/CustomFilter.vue';
import axios from "@/scripts/axios.js";

export default {
        components: {
            sidebar,
            CustomFilter
        },
        data() {
            return {
                selectAllChk: false,
                selectChk: [],
                selectedItems: [],
                allItems: [],
                filterOptions:[
                    { value: "received_timerange", title: "Create Date" },
                    { value: "reminder_message", title: "Message" },
                    { value: "patient_name", title: "Demographic" },
                    { value: "provider_name", title: "Assigned Provider" },
                    { value: "task.status", title: "Status" },
                ],
                isShowCustomFilter:false,

                allCompDelTasksList: [],
                loader: false,
                filterList:[]
            }
        },
        mounted() {
            this.resetTaskForm();
            document.addEventListener('click', this.handleClickOutside);
            this.form.sort_by = 'created_datetime';

            const authUserRole = localStorage.getItem("authUserRole");
            if (authUserRole === 'Doctor' || authUserRole === 'Nurse') {
                const authUserName = localStorage.getItem('authUserName');
                this.filterList= [{field: "provider_name", value: authUserName}]
                this.handleCustomField(this.filterList)
            }

            this.getCompDelTaskList();
        },
        methods:{
            activeTask(changeStatus) {
                this.$swal({
                    title: 'Are you sure to ACTIVATE this task?',
                    text: '',
                    icon: '',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Activate',
                    cancelButtonText: 'Cancel',
                    closeOnConfirm: false,
                    closeOnCancel: false,
                    reverseButtons: true, // This will swap the Confirm and Cancel buttons
                    customClass: {
                        container: 'my-swal-container',
                        popup: 'my-swal-popup delete-popup',
                        header: 'my-swal-header',
                        title: 'my-swal-title',
                        closeButton: 'my-swal-close-button',
                        icon: 'my-swal-icon',
                        image: 'my-swal-image',
                        content: 'my-swal-content',
                        input: 'my-swal-input',
                        actions: 'my-swal-actions',
                        confirmButton: 'my-swal-confirm-button',
                        cancelButton: 'my-swal-cancel-button',
                        footer: 'my-swal-footer'
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        // console.log('selectedItems:-  ', this.selectedItems);
                        if(this.selectedItems)
                        {
                            axios.post("patient/task/update-status", { status: changeStatus, ids: this.selectedItems } )
                            .then((response) => {
                                // console.log('response:- ', response.data.data);
                                this.getCompDelTaskList();
                                this.selectedItems = []
                                this.selectAllChk  = false
                                this.$filters.moshaToast(response.data.message, "success");
                            }).catch(error => {
                                if (error.response.status === 422) {
                                    this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                                    // console.log(Object.values(error.response.data.data).join(', '))
                                } else {
                                    this.$filters.moshaToast(error.response.data.message, "error");
                                }
                            });
                        }
                    }
                })
            },
            isChecked(id){
                return this.selectedItems.includes(id);
            },
            checkSelectAll(){
                if (this.selectAllChk) {
                    this.selectedItems = [...this.allItems];
                } else {
                    this.selectedItems = []
                }
            },
            checkSelect(id){
                // Handle individual item selection/deselection
                const index = this.selectedItems.indexOf(id); // Find index of id in selectedItems
                if (index !== -1) {
                    this.selectedItems.splice(index, 1); // Remove id from selectedItems
                } else {
                    this.selectedItems.push(id)
                }

                // Update selectAllChk based on selectedItems length
                this.selectAllChk = this.selectedItems.length === this.allItems.length;
            },
            changeSort(orderColumnName, event){
                event.stopPropagation();
                const th = event.target;

                this.form.sort_by = orderColumnName;
                if (th.classList.contains('active')) {
                    th.classList.remove('active');
                    this.form.sort_order = 'asc';
                } else {
                    th.classList.add('active');
                    this.form.sort_order = 'desc';
                }

                this.getCompDelTaskList();
            },
            capitalizeData(data) {
                console.log(data)
                return data.map(item => ({
                    ...item,
                    patient_name: this.$filters.strToUpperCase(item.patient_name),
                    provider_ids: this.$filters.strToUpperCase(item.provider_ids)
                }));
            },
            getCompDelTaskList() {
                this.$store.state.loader = true;
                this.form.from_module = 'calendar';
                this.form.from =  'compDelTaskList';
				if(this.$route.params.patient_id){
					this.form.patient_id = this.$route.params.patient_id;
				}
                axios.post('patient/task/list', {
                    'patient_id':this.form.patient_id,
                    'from':this.form.from,
                    'from_module':this.form.from_module,
                    'advanceFilter':this.form.advanceFilter,
                    'keyword':this.form.keyword,
                    'sort_order':this.form.sort_order,
                    'sort_by':this.form.sort_by,
                })
                    .then((response) => {
                        this.allItems = []
                        this.$store.state.loader = false;
                        if (response.status == 200) {
                            this.allCompDelTasksList = response.data.data;
                            if(this.allCompDelTasksList) {
                                this.allCompDelTasksList = this.capitalizeData(this.allCompDelTasksList);
                            }
                            this.allCompDelTasksList.forEach(item => {
                                this.allItems.push(item.id)
                            });
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        this.allItems = []
                        this.$store.state.loader = false;
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            // console.log(Object.values(error.response.data.data).join(', '))
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            },
            taskSearch() {
                if(this.form.keyword.length > 2)
                {
                    this.getCompDelTaskList();
                }
                if(this.form.keyword.length == 0)
                {
                    this.getCompDelTaskList();
                    if(this.allCompDelTasksList) {
                        this.allCompDelTasksList = this.capitalizeData(this.allCompDelTasksList);
                    }
                }
            },
            addFilter(){
                this.isShowCustomFilter = !this.isShowCustomFilter;
            },
            handleCustomField(values){
                this.filterList = values
                let advanceFilter={}
                values.forEach(item => {
                    let value = item.value
                    if (item.value == 'Not Set') {
                        value = ''
                    }
                    advanceFilter[item.field] = value
                });
                this.form.advanceFilter = advanceFilter
            },
            handleClickOutside(event) {
                if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.a_header')) {
                    this.getCompDelTaskList();
                    this.isShowCustomFilter = false;
                }

            },
            resetTaskForm() {
                Object.assign(
                    this.$store.state.tasks.form,
                    this.$store.state.tasks.defaultFormData,
                );
            },
        },
        computed: {
            form() {
                return this.$store.state.tasks.form;
            },
        },
        validations() {
            return this.$store.state.tasks.validationRules;
        },
}
</script>